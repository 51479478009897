import { BellContainer, ErrorNumber, ErrorNumberText } from "../../../order-line/pages/order-line.page.style";
import { IOrderList, IOrderStatus, ISortColumn } from "../../interfaces/order-list";
import { OrderListStateService, _getOriginalOrder, _setCurrentOrderList, _setDeletedOrderList, _toggleCreateOrderListModal, _toggleDeleteOrderListModal } from "../../services/order-list-state.service";

import { ColumnsType } from "antd/lib/table";
import { DownloadButton } from "./order-list-table.style";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IPagination } from "../../../shared/interfaces/pagination";
import { IntegrationStatus } from "../integration-status/integration-status";
import { OrderListService } from "../../services/order-list.service";
import React from 'react';
import { TablePaginationConfig } from "antd";
import { colors } from "../../../shared/styles/colors";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import moment from 'moment';

export class OrderListTableUtils {
    public static columns(fromOrderLine: boolean, initSortColumn: ISortColumn): ColumnsType<IOrderList> {
        return [{
            title: '',
            render: (_text: string, orderList: IOrderList) =>
                OrderListTableUtils.renderBell(orderList, fromOrderLine),
        }, {
            title: 'ERP Order ID',
            key: 'id',
            sorter: !fromOrderLine,
            defaultSortOrder: this.getOrderFromInitColumn('id', initSortColumn),
            render: (_text: string, orderList: IOrderList) =>
                <div style={{ width: 100 }}>
                    {orderList.erpOrderId}
                </div>,
        }, {
            title: 'Company Name',
            key: 'companyName',
            sorter: !fromOrderLine,
            defaultSortOrder: this.getOrderFromInitColumn('companyName', initSortColumn),
            render: (_text: string, orderList: IOrderList) =>
                <div style={{ width: 120 }}>
                    {orderList.companyName}
                </div>
        }, {
            title: 'Customer ID',
            sorter: !fromOrderLine,
            key: 'externalCustomerId',
            defaultSortOrder: this.getOrderFromInitColumn('externalCustomerId', initSortColumn),
            render: (_text: string, orderList: IOrderList) =>
                <div style={{ width: 100 }}>
                    {orderList.externalCustomerId}
                </div>
        }, {
            title: 'PO Number',
            key: 'poNumber',
            sorter: !fromOrderLine,
            className: 'ponumber-column',
            defaultSortOrder: this.getOrderFromInitColumn('poNumber', initSortColumn),
            render: (_text: string, orderList: IOrderList) =>
                <div style={{ width: 120 }}>
                    {orderList.poNumber}
                </div>
        }, {
            title: 'Currency',
            dataIndex: 'currency',
            key: 'currency',
            sorter: !fromOrderLine,
            defaultSortOrder: this.getOrderFromInitColumn('currency', initSortColumn),
        }, {
            title: 'PO Received Date',
            render: (_text: string, orderList: IOrderList) => {
                return (
                    <div style={{ width: 150 }}>
                        {moment(orderList.orderDate).format('MM/DD/YYYY')}
                    </div>
                );
            },
            key: 'orderDate',
            sorter: !fromOrderLine,
            defaultSortOrder: this.getOrderFromInitColumn('orderDate', initSortColumn),
        }, {
            title: 'Original Document',
            render: (_text: string, orderList: IOrderList) =>
                <div style={{ width: 150 }}>
                    {
                        !!orderList.emailAttachmentBlobName &&
                        <DownloadButton onClick={(event) => OrderListTableUtils.handleDownloadButton(orderList, event)}>
                            Download
                        </DownloadButton>
                    }
                </div>
        }, {
            title: 'Order Status',
            key: 'orderStatus',
            sorter: !fromOrderLine,
            defaultSortOrder: this.getOrderFromInitColumn('orderStatus', initSortColumn),
            render: (_text: string, orderList: IOrderList) => {
                let currentStatus: IOrderStatus = OrderListService.getCurrentStatus(orderList.orderStatus);
                let name = '';

                if (currentStatus) {
                    name = currentStatus.name;
                }

                return (
                    <IntegrationStatus status={currentStatus}>
                        {name}
                    </IntegrationStatus>
                );
            }
        }, {
            title: 'Total',
            key: 'totalAmount',
            sorter: !fromOrderLine,
            defaultSortOrder: this.getOrderFromInitColumn('totalAmount', initSortColumn),
            render: (_text: string, orderList: IOrderList) =>
                <>
                    {orderList.totalAmount && orderList.totalAmount.toFixed(2)}
                </>,
        }];
    }

    public static pagination(
        paginationConfig?: IPagination<IOrderList>,
        onPageChange?: (page: number, pageSize?: number) => void
    ): TablePaginationConfig {
        if (!paginationConfig) {
            return {};
        }

        const { pageSize, totalRecords, pageNumber } = paginationConfig;

        return {
            pageSize: pageSize,
            current: pageNumber,
            onChange: (page: number, pageSize?: number) => onPageChange && onPageChange(page, pageSize),
            total: totalRecords,
            showSizeChanger: false,
        };
    }

    public static handleDelete = (orderList: IOrderList) => {
        _setDeletedOrderList(orderList);
        _toggleDeleteOrderListModal(true);
    }

    public static handleEdit = (orderList: IOrderList) => {
        _setCurrentOrderList(orderList);
        _toggleCreateOrderListModal(true);
    };

    public static handleView = (orderList: IOrderList) => {
        _setCurrentOrderList(orderList);
        _toggleCreateOrderListModal(true);
        OrderListStateService.setViewMode(true);
    }

    public static handleUpdateAddress = (orderList: IOrderList) => {
        _setCurrentOrderList(orderList);
        OrderListStateService.toggleUpdateAddressModal(true);
    };

    private static renderBell = (orderList: IOrderList, fromOrderLine: boolean) => {
        if (fromOrderLine) {
            if (orderList.errorsList && orderList.errorsList.length) {

                return (
                    <BellContainer>
                        <FontAwesomeIcon icon={faBell}
                            color={colors.$warning}
                            size={'lg'} />

                        <ErrorNumber>
                            <ErrorNumberText>
                                {orderList.errorsList && orderList.errorsList.length}
                            </ErrorNumberText>
                        </ErrorNumber>
                    </BellContainer>
                );
            }
        } else {
            if (orderList.orderStatus === 2) {
                return (
                    <FontAwesomeIcon icon={faBell}
                        color={colors.$warning}
                        size={'lg'} />
                );
            }
        }

        return <></>;
    }

    private static handleDownloadButton = (orderList: IOrderList, event: any) => {
        event.stopPropagation();

        _getOriginalOrder(orderList);
    }

    private static getOrderFromInitColumn(columnName: string, initColumn: ISortColumn) {
        if (columnName === initColumn.column) {
            return initColumn.order;
        }

        return null;
    }
}
