export const config = {
    appId: 'c7cc067f-218e-4ec3-80ad-4aa335309625',
    authority: 'https://login.microsoftonline.com/f193f6f5-62be-492d-897d-776cde125585/',
    // redirectUri: 'http://localhost:3000',
    redirectUri: 'https://customer.geckoalliance.com',
    apiUrl: 'https://api.customer.geckoalliance.com/',
    scopes: ['api://86c2de85-b1e8-4ed5-90f7-3d2dac014803/general.scope'],
    isAC: false,
    b2c: {
        tenant: "geckob2c.onmicrosoft.com",
        scopes: ['https://geckob2c.onmicrosoft.com/api/General.Scope'],
        signInPolicy: "B2C_1A_SignIn",
        applicationID: "6155b2c2-8615-4133-914f-c3586a9c5ddd",
        instance: `https://geckob2c.b2clogin.com/`,
    },
};
