import CartIcon from "../../shared/assets/icons/shopping-cart-alt.svg";
import { Config } from "../../config";
import DocumentIcon from "../../shared/assets/icons/Document.svg";
import EditIcon from "../../shared/assets/icons/file-edit-alt.svg";
import { ICardPost } from "../interfaces/card.interface";
import { ITokenUserRole } from "../../users/interfaces/token-user-role";
import PodiumIcon from "../../shared/assets/icons/Podium.svg";
import PresentationIcon from "../../shared/assets/icons/Presentation.svg";
import QrCodeIcon from "../../shared/assets/icons/qrcode-scan.svg";
import { ROLES } from "../../users/interfaces/roles";
import VideoIcon from "../../shared/assets/icons/Video.svg";

// Data for dashboard body cards
export const BODY_CARDS_DATA: ICardPost[] = [
    {
        icon: DocumentIcon,
        title: "Documentations",
        description:
            "TechBooks, manuals, start-up and troubleshooting guides, QRCs and more.",
        url: Config.gecko.links.documentations
    },
    {
        icon: VideoIcon,
        title: "Clips",
        description:
            "Video vignettes, promo clips, interviews and pro tips for spa professionals.",
        url: Config.gecko.links.clips
    },
    {
        icon: PodiumIcon,
        title: "Events",
        description:
            "Gecko’s North-American and European industry trade show schedule",
        url: Config.gecko.links.events
    },
    {
        icon: PresentationIcon,
        title: "Seminars",
        description:
            "Workshops on everything Gecko: systems, keypads, pumps and accessories.",
        url: Config.gecko.links.seminars
    }
];

// Data for dashboard header cards

export const HEADER_CARDS_DATA = (tokenUserRole: ITokenUserRole | null): ICardPost[] => ([
    {
        icon: CartIcon,
        title: "Orders",
        description:
            "View all the information relative to your orders, shipments, price lists, invoices.",
        url: "/orders",
        isHeaderCard: true
    },
    {
        icon: EditIcon,
        title: "Fast Order Entry",
        description:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor",
        // mock data url
        url: "/fast-order-entry",
        isHeaderCard: true
    },
    {
        icon: QrCodeIcon,
        title: "Automated Order Entry",
        description:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod",
        // mock data url
        url: "/automated-order-entry",
        isHeaderCard: true,
        hide: (tokenUserRole && (tokenUserRole.role === ROLES.Client || tokenUserRole.role === ROLES.ClientFinance)) || false,
    }
]);
