import { CompaniesStateService, _getCompanies } from '../../companies/services/companies.state-service';
import { ICustomer, IFreightPref, IIncoterm } from '../../customers/interfaces/customer';
import { ICustomerPart, ICustomerPartDTO } from '../../customers/interfaces/customer-parts';
import { IFoeCustomerPoLine, IFoeOrder, IUserAvailableCustomer } from '../interfaces/foe';
import React, { useEffect, useState } from 'react';
import { isEmpty, toArray } from 'lodash';

import { ActivityIndicator } from '../../shared/components/activity-indicator/activity-indicator';
import { AppState } from '../../shared/interfaces/app.state';
import { Button } from '../../shared/components/button/button';
import { ButtonTypes } from '../../shared/components/button/button.utils';
import { ColumnsType } from 'antd/lib/table';
import { CustomersStateService } from '../../customers/services/customers.state-service';
import { DateSelector } from '../../shared/components/date-selector/date-selector';
import { Dropdown } from '../../shared/components/dropdown/dropdown';
import { FoeStateService } from '../services/foe.state-service';
import { GeckoTable } from '../../shared/components/gecko-table/gecko-table';
import { IAzureADFunctionProps } from 'react-aad-msal';
import { ICompany } from '../../companies/interfaces/company';
import { ICustomerDetails } from '../../customers/interfaces/customer-details';
import { ISetting } from '../../users/interfaces/settings';
import { IShippingAddress } from '../../customers/interfaces/shipping-address';
import { ISoa } from '../../soa/interfaces/soa';
import { ITokenUserRole } from '../../users/interfaces/token-user-role';
import { Input } from '../../shared/components/input/input';
import { PageTitle } from '../../shared/components/page-title/page-title';
import { ROLES } from '../../users/interfaces/roles';
import { SectionTitle } from '../../shared/components/section-title/section-title';
import { SoaStateService } from '../../soa/services/soa-state.service';
import { Theme } from '../../shared/styles/theme';
import { UsersStateService } from '../../users/services/users-state.service';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { Console } from 'console';

interface Props extends IAzureADFunctionProps {}

const dateFormat = 'MM/DD/YYYY';

export const NewEntryPage: React.FC<Props> = (_props: Props) => {
    const company = useSelector<AppState, ICompany>(state => state.companies.company);
    const companies = useSelector<AppState, string[]>(state => state.companies.erpCompanies);
    const customers = useSelector<AppState, string[]>(state => state.customers.erpCustomerIds);
    const freights = useSelector<AppState, IFreightPref[]>(state => state.customers.freights);
    const incoterms = useSelector<AppState, IIncoterm[]>(state => state.customers.incoterms);
    const customerParts = useSelector<AppState, ICustomerPart[]>(state => state.customers.customerParts);
    const customerDetails = useSelector<AppState, ICustomerDetails>(state => state.customers.customerDetails);
    const shippingAddresses = useSelector<AppState, IShippingAddress[]>(state => state.customers.shippingAddresses);
    const generalSettings = useSelector<AppState, ISetting[]>(state => state.users.settings);
    const response = useSelector<AppState, string>(state => state.foe.response);
    const customerObject = useSelector<AppState, ICustomer | null>(state => state.customers.customerByExternalId.response);
    const customersForUser = useSelector<AppState, ICustomerDetails[]>(state => state.customers.customersForUser);
    const soa = useSelector<AppState, ISoa>(state => state.soa.soa);

    // loaders
    const foeCreateLoading = useSelector<AppState, boolean>(state => state.foe.loading);
    const customerByExternalIdLoading = useSelector<AppState, boolean>(state => state.customers.customerByExternalIdLoading);
    const incotermsLoading = useSelector<AppState, boolean>(state => state.customers.incotermsLoading);
    const freightsLoading = useSelector<AppState, boolean>(state => state.customers.freightsLoading);
    const customerPartsLoading = useSelector<AppState, boolean>(state => state.customers.customerPartsLoading);
    const customerDetailsLoading = useSelector<AppState, boolean>(state => state.customers.customerDetailsLoading);
    const shippingAddressesLoading = useSelector<AppState, boolean>(state => state.customers.shippingAddressesLoading);

    // errors
    const customerError = useSelector<AppState, string | null>(state => state.customers.customerByExternalId.error);

    const tokenUserRole = useSelector<AppState, ITokenUserRole | null>(state => state.users.tokenUserRole);

    const getInitialFreight = () => {
        if (!isEmpty(customerObject) && customerObject!.freightPreference) {
            let f = freights.find(fr => fr.name === customerObject!.freightPreference);

            return f;
        }

        return {} as IFreightPref;
    };

    const getInitialIncoterm = () => {
        if (!isEmpty(customerObject) && customerObject!.dfltIncoTerm) {
            let inco = incoterms.find(i => i.code === customerObject!.dfltIncoTerm);

            return inco;
        }

        return {} as IIncoterm;
    };

    const now = moment();

    const [currentCompany, setCurrentCompany] = useState<string>('');
    const [currentCustomer, setCurrentCustomer] = useState<string>('');
    const [orderDate, setOrderDate] = useState(now);
    const [poNumber, setPoNumber] = useState('');
    const [desiredShipDate, setDesiredShipDate] = useState(now);
    const [freight, setFreight] = useState(getInitialFreight());
    const [incoterm, setIncoterm] = useState(getInitialIncoterm());
    const [billTo, setBillTo] = useState('');
    const [address, setAddress] = useState<IShippingAddress | null>(null);
    const [shipTo, setShipTo] = useState('');

    const [subtotal, setSubtotal] = useState(0);
    const [total, setTotal] = useState(0);

    const [currentCustomerParts, setCurrentCustomerParts] = useState<ICustomerPartDTO[]>([]);
    const [shipVia, setShipVia] = useState('');
    const [fobNoteDisplay, setFOBNoteDisplay] = useState(false);
    const [loading, setLoading] = useState(true);
    const [isB2C, setIsB2C] = useState<boolean | null>(null);
    const [availableCustomers, setAvailableCustomers] = useState<IUserAvailableCustomer[]>([]);
    const [userSelectedCustomer, setUserSelectedCustomer] = useState<IUserAvailableCustomer>();

    const render = () => {
        if (loading || foeCreateLoading) {
            return <ActivityIndicator />;
        }

        return (
            <Theme.PageWrapper>
                <PageTitle text={'Purchase Order'} />
                {
                    !isB2C &&
                    <Theme.Section>
                        <SectionTitle text={'Select Company'} />

                        <Theme.SpaceBetweenTop>
                            <Theme.Section width={'600px'}>
                                <Dropdown options={companies}
                                    keyToShow={''}
                                    value={currentCompany}
                                    required={true}
                                    onChange={company => onCompanyChange(company)}
                                    label={'Company'} />
                            </Theme.Section>

                            <Theme.Section width={'600px'}>
                            {
                                !isEmpty(currentCompany) &&
                                    <Dropdown options={customers}
                                        keyToShow={''}
                                        value={currentCustomer}
                                        label={'Customer'}
                                        required={true}
                                        onChange={customer => setCurrentCustomer(customer)} />
                            }
                            </Theme.Section>
                        </Theme.SpaceBetweenTop>
                    </Theme.Section>
                }

                {
                    isB2C &&
                    <Theme.Section>
                        <SectionTitle text={'Select Currency'} />

                        <Theme.SpaceBetweenTop>
                            <Theme.Section width={'600px'}>
                                <Dropdown options={availableCustomers}
                                    keyToShow={'externalId'}
                                    value={userSelectedCustomer}
                                    required={true}
                                    onChange={customer => { setShipVia(''); setPoNumber(''); setUserSelectedCustomer(customer); }}
                                    label={'Choose your account'} />
                            </Theme.Section>

                            <Theme.Section width={'600px'}>
                            {
                                !isEmpty(userSelectedCustomer) &&
                                <Dropdown options={[]}
                                    keyToShow={'currency'}
                                    disabled={true}
                                    value={userSelectedCustomer}
                                    label={'Currency'}
                                    onChange={customer => setCurrentCustomer(customer)} />
                            }
                            </Theme.Section>
                        </Theme.SpaceBetweenTop>
                    </Theme.Section>
                }

                {
                    isDataLoading() &&
                    <ActivityIndicator />
                }

                {
                    displayDetails() &&
                    <>
                        <Theme.SpaceBetweenTop>
                            {/** Left */}
                            <Theme.Section width={'600px'}>
                                <Input
                                    value={billTo}
                                    isTextArea={true}
                                    disabled={true}
                                    maxLength={255}
                                    inputId={'billTo'}
                                    label={"Bill To"}
                                />

                                <Input value={customerDetails?.contactPhone || ''}
                                    disabled={true}
                                    label={'Tel'} />

                                <Dropdown options={shippingAddresses}
                                    keyToShow={'revisedShipToAddressLine1'}
                                    label={'Ship to'}
                                    required={true}
                                    allowClear={true}
                                    onChange={address => setAddress(address)} />

                                <Input
                                    value={shipTo}
                                    isTextArea={true}
                                    maxLength={1000}
                                    inputId={'shipTo'}
                                    required={true}
                                    maxRows={10}
                                    onChange={value => setShipTo(value)}
                                />
                            </Theme.Section>

                            <Theme.Section width={'600px'}>
                                <DateSelector value={orderDate}
                                    onChange={date => setOrderDate(date)}
                                    disabled={true}
                                    label={'Order Date'} />

                                <Input value={poNumber || ''}
                                    label={'Purchase Order Number'}
                                    onChange={poNumber => setPoNumber(poNumber)} />

                                <DateSelector value={desiredShipDate}
                                    onChange={date => setDesiredShipDate(date)}
                                    label={'Desired Ship Date'} />

                                <Input value={customerObject?.freightPreference || ''}
                                    disabled={true}
                                    label={'Shipping Terms'} />

                                <Input value={shipVia}
                                    //disabled={!shipViaDisabled}
                                    label={'Ship Via'}
                                    onChange={value => setShipVia(value)} />

                                {/* <Dropdown options={shipVias}
                                    keyToShow={'code'}
                                    disabled={isShipViaDisabled()}
                                    label={'Ship Via'}
                                    allowClear={true}
                                    onChange={value => setShipVia(value)} /> */}

                                <Dropdown options={incoterms}
                                    keyToShow={'code'}
                                    value={incoterm}
                                    label={'Incoterm'}
                                    disabled={true}
                                    allowClear={true}
                                    onChange={incoterm => setIncoterm(incoterm)} />
                            </Theme.Section>
                        </Theme.SpaceBetweenTop>

                        <Theme.Section>
                            <GeckoTable dataSource={{ data: currentCustomerParts }}
                                filters={[]}
                                tableId={'foeOrders'}
                                columns={columns()} />

                            <Theme.Section style={{ marginTop: 50, textAlign: 'right' }}>
                                <SectionTitle text={`Subtotal: ${subtotal.toFixed(2)} ${customerObject?.currency || ''}`} />

                                <Button type={ButtonTypes.PRIMARY}
                                    onClick={() => onSubmit()}
                                    disabled={isSubmitDisabled()}
                                    overrides={{ root: 'margin-bottom: 50px; margin-top: 50px;' }}
                                    label={'Confirm Purchase Order'} />
                            </Theme.Section>

                        </Theme.Section>

                        <Theme.Section style={{ paddingBottom: 100 }}>
                            <SectionTitle text={'Notes'} />
                            
                            <div>To add a part number to the list or to change the billing Address, telephone number or to make changes after having confirmed the order
                                , please contact your sales operation agent ({soa.name}) at {soa.soaEmail}</div>

                            <div>Desired quantity must be at least the MOQ and then either box or MOQ multiples</div>
                                                      
                            <Theme.Section hidden={!fobNoteDisplay}>
                                <div>Conditions for prepaid shipment will be evaluated when order is confirmed</div>
                            </Theme.Section>
                            
                        </Theme.Section>
                    </>
                }

                {
                    displayError()
                }

            </Theme.PageWrapper>
        );
    };

    useEffect(() => {
        UsersStateService.getAdminSettings();
    }, []);

    useEffect(() => {
        if (customers.length === 1) {
            setCurrentCustomer(customers[0]);
        }
    }, [customers]);

    useEffect(() => {
        if (!isB2C) {
            if (currentCompany && !currentCustomer) {
                CustomersStateService.getCustomerIdsFromErpCompany(currentCompany);
            }

            if (currentCustomer && currentCompany) {
                setPoNumber('');
                setShipVia('');
                // setIncoterm({} as IIncoterm);
                setShipTo('');

                CustomersStateService.getFreights();
                CustomersStateService.getIncoterm();
                CustomersStateService.getCustomerByExternalId(currentCustomer);
                CustomersStateService.getCustomerParts(currentCustomer);
                CustomersStateService.getCustomerDetails(currentCustomer);
                CustomersStateService.getShippingAddress(currentCustomer);
            }

            if (currentCompany) {
                CustomersStateService.resetCustomerByExternalIdError();
                // setCurrentCustomer('');
            }
        }
    }, [currentCompany, currentCustomer]);

    useEffect(() => {
        if (currentCompany) {
            setCurrentCustomer('');
        }
    }, [currentCompany]);

    useEffect(() => {
        // calculate currencies
        if (customersForUser.length) {
            let customers: IUserAvailableCustomer[] = customersForUser.map(c => ({ id: c.id, currency: c.currency, externalId: c.erpcustomerId }));

            setAvailableCustomers(customers);

            if (customers.length === 1) {
                setUserSelectedCustomer(customers[0]);
            }
        }
    }, [customersForUser]);

    useEffect(() => {
        if (!isEmpty(userSelectedCustomer)) {
            const externalId = userSelectedCustomer!.externalId;

            CustomersStateService.getFreights();
            CustomersStateService.getIncoterm();
            CustomersStateService.getCustomerByExternalId(externalId);
            CustomersStateService.getCustomerParts(externalId);
            CustomersStateService.getCustomerDetails(externalId);
            CustomersStateService.getShippingAddress(externalId);
        }
    }, [userSelectedCustomer]);

    useEffect(() => {
        if (isB2C === false) {
            CompaniesStateService.getErpCompanies();
        } else if (isB2C) {
            CustomersStateService.getErpCustomersForUser();
        }
    }, [isB2C]);

    useEffect(() => {
        if (!isEmpty(tokenUserRole)) {
            if (tokenUserRole?.role === ROLES.Client || tokenUserRole?.role === ROLES.ClientFinance) {
                setIsB2C(true);
            } else {
                setIsB2C(false);
            }

            setLoading(false);
        }
    }, [tokenUserRole]);

    useEffect(() => {
        calculateSubtotal();
    }, [currentCustomerParts]);

    useEffect(() => {
        // add taxes here
        setTotal(subtotal);
    }, [subtotal]);

    useEffect(() => {
        setFreight(getInitialFreight());
    }, [freights]);

    useEffect(() => {
        setIncoterm(getInitialIncoterm());
    }, [incoterms]);

    useEffect(() => {
        let newCustomerParts: ICustomerPartDTO[] = customerParts.map((part, index) => ({
            ...part,
            desiredQty: '0',
            desiredShipDate: null,
            lineAmount: 0,
            id: index + 1,
        }));

        setDesiredShipDate(getDefaultDesiredShipDate());
        setCurrentCustomerParts(newCustomerParts);
    }, [customerParts, generalSettings]);

    useEffect(() => {
        if (!isEmpty(customerDetails)) {
            const { name, addressLine1, addressLine2, addressLine3, contactEmail } = customerDetails;

            let currentBillTo = '';
            if (name) {
                currentBillTo += (name + '\n');
            }

            if (addressLine1) {
                currentBillTo += (addressLine1 + '\n');
            }

            if (addressLine2) {
                currentBillTo += (addressLine2 + '\n');
            }

            if (addressLine3) {
                currentBillTo += (addressLine3 + '\n');
            }

            if (contactEmail) {
                currentBillTo += contactEmail;
            }

            setBillTo(currentBillTo);
        }

    }, [customerDetails]);

    useEffect(() => {
        if (!isEmpty(response)) {
            if (!isB2C) {
                setCurrentCustomerParts([]);
                setCurrentCompany('');
                setCurrentCustomer('');
            } else {
                setPoNumber('');
                setShipVia('');

                if (availableCustomers && availableCustomers.length === 1) {
                    setUserSelectedCustomer(availableCustomers[0]);
                } else {
                    setUserSelectedCustomer(undefined);
                }

                let newCustomerParts: ICustomerPartDTO[] = customerParts.map((part, index) => ({
                    ...part,
                    desiredQty: '0',
                    desiredShipDate: null,
                    lineAmount: 0,
                    id: index + 1,
                }));

                setDesiredShipDate(getDefaultDesiredShipDate());
                setCurrentCustomerParts(newCustomerParts);
            }
        }
    }, [response]);

    useEffect(() => {
        if (address) {
            let shipTo = getShipToValue(address);

            setShipTo(shipTo);
        }
    }, [address]);

    useEffect(() => {
        if (shippingAddresses.length) {
            setShipTo(getShipToValue(shippingAddresses[0]));
        }
    }, [shippingAddresses]);

    useEffect(() => {
        if (!isEmpty(customerObject)) {
            setIncoterm(getInitialIncoterm());
            setFreight(getInitialFreight());

            CompaniesStateService.getCompanyById(customerObject!.companyId.toString());
        }

        if (customerObject && customerObject.freightPreference) {
            const name = customerObject.freightPreference;

            if (name === 'PREPAID/COLLECT' || name === 'PREPAID/PREPAID AND ADD') {
                setFOBNoteDisplay(true);
            } else {
                setFOBNoteDisplay(false);
            }
        } else {
            setFOBNoteDisplay(false);
        }
    }, [customerObject]);

    useEffect(() => {
        if (!isEmpty(company)) {
            SoaStateService.getSoaById(parseInt(company!.soaId));
        }
    }, [company]);

    const displayDetails = () => {
        if (!isEmpty(customerError)) {
            return false;
        }

        if (!isEmpty(currentCustomer) && !isDataLoading()) {
            return true;
        }

        if (!isDataLoading() && !isEmpty(userSelectedCustomer)) {
            return true;
        }

        return false;
    };

    const isDataLoading = () => {
        return customerByExternalIdLoading ||
            incotermsLoading ||
            freightsLoading ||
            customerPartsLoading ||
            customerDetailsLoading ||
            shippingAddressesLoading;
    };

    const getQuantityPrice = (part: ICustomerPartDTO) => {
        let parts = currentCustomerParts
            .filter(cp => parseInt(cp.desiredQty) > 0)
            .filter(cp => cp.id === part.id);

        let price = part.defaultUnitPrice;

        if (parts.length === 1) {
            const partFound = parts[0] as any;

            for (let i = 1; i <= 10; i++) {
                if (partFound[`qtyBreak${i}`] <= partFound.desiredQty) {
                    price = partFound[`unitPrice${i}`];
                }
            }
        }

        return price ? price : getTablePriceIfDefaultNotAvailable(part);
    };

    const columns = (): ColumnsType<ICustomerPartDTO> => {
        return [{
            title: 'Ln #',
            dataIndex: 'id'
        }, {
            title: 'Customer part number',
            width: 200,
            render: (_text: string, part: ICustomerPartDTO) => {
                return part.customerPartId;
            },
        }, {
            title: 'Part number',
            dataIndex: 'partId',
        }, {
            title: 'Description',
            dataIndex: 'description',
        }, {
            title: 'Unit Price',
            render: (_text: string, part: ICustomerPartDTO) => {
                return getQuantityPrice(part);
            },
        }, {
            title: 'MOQ',
            dataIndex: 'moq',
        }, {
            title: 'Box Qty',
            dataIndex: 'caseQty',
        }, {
            title: 'Desired Qty',
            width: 100,
            render: (_text: string, part: ICustomerPartDTO) => {
                let quantities = calculateFirst100Qty(part);
                let line = currentCustomerParts.filter(f => f.id === part.id)[0];

                return (
                    <Dropdown keyToShow={''}
                        value={line.desiredQty}
                        options={toArray(quantities)}
                        allowClear={true}
                        onChange={value => onChangeTableValue(value, 'desiredQty', part.id)} />
                );

            },
        }, {
            title: 'Line Amount',
            width: 150,
            render: (_text: string, part: ICustomerPartDTO) => {
                let line = currentCustomerParts.filter(f => f.id === part.id)[0];

                return (
                    <Input value={`${line.lineAmount.toFixed(2)} ${customerObject?.currency || ''}`}
                        disabled={true} />
                );
            },
        }, {
            title: 'Desired Ship Date',
            dataIndex: 'desiredShipDate',
            width: 200,
            render: (_text: string, part: ICustomerPartDTO) => {
                let line = currentCustomerParts.filter(f => f.id === part.id)[0];

                return (
                    <DateSelector value={line.desiredShipDate ? moment(line.desiredShipDate) : null}
                        onChange={value => onChangeTableValue(value, 'desiredShipDate', part.id)}
                        />
                );
            },
        }];
    };

    const onChangeTableValue = (value: any, key: string, lineId: number) => {
        let newCustomerParts = currentCustomerParts.map(cf => {
            if (cf.id === lineId) {
                let lineAmount = 0;
                if (cf.lineAmount != null)
                {
                    lineAmount = cf.lineAmount;
                }

                // update lineAmount also
                if (key === 'desiredQty') {
                    lineAmount = 0;
                    let qtyKeys = Object.keys(cf).filter(key => {
                        return key.toLowerCase().includes('qtyBreak'.toLowerCase());
                    });

                    let qtyMatchingNumberKey = 0;

                    qtyKeys.forEach(key => {
                        if (value >= (cf as any)[key]) {
                            qtyMatchingNumberKey = parseInt(key.charAt(key.length - 1));
                        }
                    });

                    if (cf.qtyBreak10 && value >= cf.qtyBreak10) {
                        qtyMatchingNumberKey = 10;
                    }

                    if (qtyMatchingNumberKey) {
                        let unitPriceAvailable = (cf as any)[`unitPrice${qtyMatchingNumberKey}`];

                        lineAmount = (unitPriceAvailable ? unitPriceAvailable : cf.defaultUnitPrice) * value;
                    } else {
                        lineAmount = cf.defaultUnitPrice * value;
                    }
                }

                return {
                    ...cf,
                    [key]: value,
                    lineAmount,
                };
            }

            return cf;
        });

        setCurrentCustomerParts(newCustomerParts);
    };

    const calculateFirst100Qty = (part: ICustomerPartDTO) => {
        let values: any[] = [];

        for (let i = 1; i <= 200; i++) {
            const moqQty = (part.moq ? part.moq : 1) * i;
            const boxQty = part.caseQty * i > part.moq ? part.caseQty * i : part.moq;

            values = values.concat([moqQty, boxQty]);
        }

        values = values.sort((a, b) => a - b);

        return new Set(values.filter(v => v));
    };
    
    const getDefaultDesiredShipDate = () => {
        let lead = 0;

        let setting = generalSettings.find(gs => gs.key === 'FixLeadTime');
        if (setting) {
            lead = parseInt(setting.value);
        }

        let desiredShipDate =  moment(now);
        let i = 0;
        while ( i < lead )
        {
            desiredShipDate.add(1, 'days');
            const weekday = desiredShipDate.format('dddd'); // Monday ... Sunday
            const isWeekend = weekday === 'Sunday' || weekday === 'Saturday';
            if (!isWeekend)
            {
                i++;
            }
        }
        return desiredShipDate;
    };

    const calculateSubtotal = () => {
        let newParts = [...currentCustomerParts];
        let subtotal = 0;

        newParts.forEach(p => subtotal += p.lineAmount);

        setSubtotal(subtotal);
    };

    const getShipToValue = (address: IShippingAddress | null) => {
        let str = '';

        if (address) {
            if (address.revisedShipToAddressLine1) {
                str += address.revisedShipToAddressLine1 + '\n';
            }

            if (address.revisedShipToAddressLine2) {
                str += address.revisedShipToAddressLine2 + '\n';
            }

            let stateAddr = '';

            if (address.revisedShipToCity) {
                stateAddr += address.revisedShipToCity;
            }

            if (address.revisedShipToState) {
                if (stateAddr) {
                    stateAddr += ', ';
                }

                stateAddr += address.revisedShipToState;
            }

            if (address.revisedShipToZipCode) {
                if (stateAddr) {
                    stateAddr += ', ';
                }

                stateAddr += address.revisedShipToZipCode;
            }

            if (address.revisedShipToCountry) {
                if (stateAddr) {
                    stateAddr += ', ';
                }

                stateAddr += address.revisedShipToCountry;
            }

            str += stateAddr;
        }

        return str;
    };

    const displayError = () => {
        if (!isEmpty(customerError)) {
            if (isB2C) {
                return (
                    <Theme.ErrorMessage>Your account is not configured, please contact your SOA.</Theme.ErrorMessage>
                );
            }

            return (
                <Theme.ErrorMessage>This customer is not configured, please configure it.</Theme.ErrorMessage>
            );
        }

        return <></>;
    }

    const onCompanyChange = (company: string) => {
        setCurrentCustomer('');
        setCurrentCompany(company);
    };

    const isSubmitDisabled = () => {
        if (isEmpty(shipTo)) {
            return true;
        }

        if (!currentCustomerParts.filter(cp => parseInt(cp.desiredQty) > 0).length) {
            return true;
        }

        return false;
    };

    const onSubmit = () => {
        let order: IFoeOrder = {} as IFoeOrder;

        order.customerId = customerObject?.id || 0;
        order.externalCustomerId = customerObject?.externalCustomerId || '';
        order.poNumber = poNumber;
        order.poDate = moment(orderDate).format(dateFormat);
        order.currency = customerObject?.currency || '';
        order.revisedFob = freight?.name || '';
        order.shipViaText = shipVia || '';
        order.incoterm = incoterm?.code || '';
        order.customerRequestedDate = moment(desiredShipDate).format(dateFormat);
        order.typeId = 4;
        order.shipToAddress = shipTo;
        order.shipToCompanyName = customerObject?.companyName || customerDetails?.billToName || '';
        order.dateFormat = 'MM/dd/yyyy';
        order.customerPoLines = currentCustomerParts.filter(cp => parseInt(cp.desiredQty) > 0).map(cp => {
            return {
                id: 0,
                headerId: 0,
                partId: cp.partId,
                customerPartId: cp.customerPartId,
                partDescription: cp.description,
                expectedDate: '',
                quantity: cp.desiredQty,
                unitPrice: (cp.lineAmount / parseInt(cp.desiredQty)).toFixed(2),
                customerRequestedDate: (cp.desiredShipDate == null ) ? null : moment(cp.desiredShipDate).format(dateFormat) ,
            } as IFoeCustomerPoLine;
        });

        FoeStateService.createFoe(order);
    };

    const getTablePriceIfDefaultNotAvailable = (part: ICustomerPartDTO) => {
        if (part.unitPrice1) {
            return part.unitPrice1;
        }

        if (part.unitPrice2) {
            return part.unitPrice2;
        }

        if (part.unitPrice3) {
            return part.unitPrice3;
        }

        if (part.unitPrice4) {
            return part.unitPrice4;
        }

        if (part.unitPrice5) {
            return part.unitPrice5;
        }

        if (part.unitPrice6) {
            return part.unitPrice6;
        }

        if (part.unitPrice7) {
            return part.unitPrice7;
        }

        if (part.unitPrice8) {
            return part.unitPrice8;
        }

        if (part.unitPrice9) {
            return part.unitPrice9;
        }

        if (part.unitPrice10) {
            return part.unitPrice10;
        }
    };

    return render();
};
