import { ButtonWrapper, FormColumn, FormRow, FormWrapper } from '../../../order-list/components/save-order-list-modal/save-order-list-modal.style';
import { IOrderLine, ISaveOrderLineReq } from '../../interfaces/order-line'
import React, { useEffect } from 'react';
import { isDate, isEmpty } from 'lodash'

import { ActivityIndicator } from '../../../shared/components/activity-indicator/activity-indicator';
import { Button } from '../../../shared/components/button/button';
import { DateSelector } from '../../../shared/components/date-selector/date-selector';
import { Dropdown } from '../../../shared/components/dropdown/dropdown';
import { IOrderList } from '../../../order-list/interfaces/order-list';
import { ISiteId } from '../../../shared/interfaces/site-id';
import { Input } from '../../../shared/components/input/input';
import { Modal } from '../../../shared/components/modal/modal';
import moment from 'moment';

interface Props {
    /**function for submit */
    onSubmit: (values: ISaveOrderLineReq) => void;
    /** function for edit submit */
    onSubmitEdit?: (values: IOrderLine) => void;
    /** function to close the modal */
    onClose: () => void;

    onValidateClick: () => void;

    /** initial values for edit mode */
    currentOrderLine: IOrderLine;

    currentOrderList: IOrderList;

    /** modal loading */
    loading?: boolean;

    viewMode?: boolean;

    headerId: number;

    orderListValidationLoading: boolean;

    partIds: string[];

    partDescriptions: string[];

    siteIds: ISiteId[];
}

/** Create Order Line Modal */
export const CreateOrderLineModal: React.FC<Props> = (props: Props) => {
    const {
        onClose,
        currentOrderLine,
        loading,
        headerId,
        onSubmitEdit,
        onSubmit,
        currentOrderList,
        orderListValidationLoading,
        partDescriptions,
        partIds,
        siteIds,
        viewMode,
    } = props;

    const isEditMode = !isEmpty(currentOrderLine);

    const getInitialExtendedPrice = (currentOrderLine: IOrderLine) => {
        if (!isEditMode) {
            return '';
        }

        if (!currentOrderLine.revisedUnitPrice) {
            return 0;
        }

        return (currentOrderLine.revisedQuantity * currentOrderLine.revisedUnitPrice)
            .toFixed(2)
            .toString();
    };

    const getInitialSiteId = () => {
        if (!isEmpty(currentOrderLine)) {
            if (currentOrderLine.siteId) {
                let siteIdFound = siteIds.filter(si => si.siteId === currentOrderLine.siteId)[0];

                return siteIdFound;
            }
        }

        return {} as ISiteId;
    };

    const getConfirmedDate = () => {
        if (!isEmpty(currentOrderLine)) {
            if (currentOrderLine.confirmedDate && isDate(new Date(currentOrderLine.confirmedDate))) {
                return moment(currentOrderLine.confirmedDate);
            }
        }

        return null;
    };

    /** fields values */
    const [inputs, setInputs] = React.useState({
        partId: isEditMode ? currentOrderLine.partId : '',
        customerPartId: isEditMode && currentOrderLine.customerPartId ? currentOrderLine.customerPartId : '',
        quantity: currentOrderLine ? currentOrderLine.quantity : '',
        revisedQuantity: currentOrderLine && currentOrderLine.revisedQuantity ? currentOrderLine.revisedQuantity : 1,
        unitPrice: isEditMode ? currentOrderLine.unitPrice : '',
        revisedUnitPrice: currentOrderLine && currentOrderLine.revisedUnitPrice !== null ? currentOrderLine.revisedUnitPrice : null,
        extendedCost: (currentOrderLine && getInitialExtendedPrice(currentOrderLine)) || '',
        partDescription: isEditMode ? currentOrderLine.partDescription : '',
        headerId: headerId,
        confirmedDate: getConfirmedDate(),
        customerRequestedDate: (currentOrderLine && currentOrderLine.customerRequestedDate) || '',
        revisedCustomerRequestedDate: (currentOrderLine && currentOrderLine.revisedCustomerRequestedDate && moment(currentOrderLine.revisedCustomerRequestedDate)) || null,
        text: (currentOrderLine && currentOrderLine.text) || '',
    });

    const [poNumber] = React.useState((currentOrderList && currentOrderList.poNumber) || '');
    const [siteId, setSiteId] = React.useState(getInitialSiteId());

    const [customErrors, setCustomErrors] = React.useState({
        partId: '',
        customerPartId: '',
        quantity: '',
        revisedQuantity: '',
        unitPrice: '',
        revisedUnitPrice: '',
        expectedDate: '',
        revisedDate: '',
        earlyShippingDate: '',
        extendedCost: '',
        partDescription: '',
        siteId: '',
        confirmedDate: '',
        customerRequestedDate: '',
        revisedCustomerRequestedDate: '',
        text: '',
        info: ''
    });

    const [internalErrors, setInternalErrors] = React.useState({});

    const handleValidation = (text: string | Date | number, key: string, type: string, required?: boolean) => {
        switch (type) {
            case 'date': {
                if (!text && required) {
                    setCustomErrors({ ...customErrors, [key]: 'Required' })
                } else {
                    setCustomErrors({ ...customErrors, [key]: '' })
                }
                break;
            }

            case 'string': {
                if (isEmpty(text) && required) {
                    setCustomErrors({ ...customErrors, [key]: 'Required' })
                } else {
                    setCustomErrors({ ...customErrors, [key]: '' })
                }
                break;
            }

            case 'dropdown': {
                if (isEmpty(text) && required) {
                    setCustomErrors({ ...customErrors, [key]: 'Required' })
                } else {
                    setCustomErrors({ ...customErrors, [key]: '' })
                }
                break;
            }

            default: {
                setCustomErrors({ ...customErrors, [key]: '' })
            }
        }
    }

    const onInternalInputErrors = (flag: boolean, key?: string) => {
        if (key) {
            if (flag) {
                setInternalErrors({ ...internalErrors, [key]: flag });
            } else {
                let copy: any = { ...internalErrors };
                delete copy[key];

                setInternalErrors(copy);
            }
        }
    };

    const onInputChange = (text: string | Date, key: string, type: string, required?: boolean) => {
        let newInputs = { ...inputs, [key]: text };

        setInputs(newInputs);

        handleValidation(text, key, type, required);
    };

    const isSaveButtonDisabled = () => {
        if (viewMode) {
            return true;
        }

        if (!isEmpty(internalErrors)) {
            return true;
        }

        const {
            partId,
            revisedQuantity,
            revisedUnitPrice,
            partDescription,
        } = inputs;

        if (!partId || !revisedQuantity || !revisedUnitPrice || !partDescription || isEmpty(siteId)) {
            return true;
        }

        return false;
    };

    const handleSubmit = () => {
        if (!isEmpty(currentOrderLine) && onSubmitEdit) {
            const submitInputs = {
                ...inputs,
                headerId: headerId,
                id: currentOrderLine && currentOrderLine.id,
                header: currentOrderLine && currentOrderLine.header,
                updatedDate: currentOrderLine && currentOrderLine.updatedDate,
                updatedBy: currentOrderLine && currentOrderLine.updatedBy,
                siteId: !isEmpty(siteId) ? siteId.siteId : '',
                confirmedDate: inputs.confirmedDate ? moment(inputs.confirmedDate).format('MM/DD/YYYY') : '',
                customerRequestedDate: inputs.customerRequestedDate ? moment(inputs.customerRequestedDate).format('MM/DD/YYYY') : '',
                revisedCustomerRequestedDate: inputs.revisedCustomerRequestedDate ? moment(inputs.revisedCustomerRequestedDate).format('MM/DD/YYYY') : '',
                revisedUnitPrice: inputs.revisedUnitPrice !== null ? inputs.revisedUnitPrice : null,
                text: inputs.text,
            };

            onSubmitEdit(submitInputs);
        }
        else {
            const submitInputs = {
                ...inputs,
                siteId: !isEmpty(siteId) ? siteId.siteId : '',
                confirmedDate: inputs.confirmedDate ? moment(inputs.confirmedDate).format('MM/DD/YYYY') : '',
                customerRequestedDate: inputs.customerRequestedDate ? moment(inputs.customerRequestedDate).format('MM/DD/YYYY') : '',
                revisedCustomerRequestedDate: inputs.revisedCustomerRequestedDate ? moment(inputs.revisedCustomerRequestedDate).format('MM/DD/YYYY') : '',
                revisedUnitPrice: inputs.revisedUnitPrice !== null ? inputs.revisedUnitPrice : null,
                text: inputs.text,
            };

            onSubmit(submitInputs);
        }
    }

    const handleDropdownChange = (key: string, valueObj: any, callback: any, required?: boolean) => {
        callback && callback();

        if (isEmpty(valueObj) && required) {
            setCustomErrors({ ...customErrors, [key]: 'Required' });
        } else {
            setCustomErrors({ ...customErrors, [key]: '' });
        }
    };

    useEffect(() => {
        // it has ValidationError status
        if (currentOrderLine) {
            const { errorsList } = currentOrderLine;

            let errorObj: any = {};

            if (errorsList && errorsList.length) {
                errorsList.forEach(err => {
                    Object.keys(customErrors).forEach(key => {
                        if (key.toLowerCase() === err.fieldName.toLowerCase()) {
                            errorObj[key] = err.errorMessage;
                        }
                    });
                });
            }

            setCustomErrors({ ...customErrors, ...errorObj });
        }
    }, [currentOrderLine]);

    if (loading || orderListValidationLoading) {
        return (
            <Modal onClose={() => onClose()} >
                <ActivityIndicator />
            </Modal>
        )
    } else {
        return (
            <Modal onClose={() => onClose()}>
                <FormWrapper>
                    <FormRow>
                        <FormColumn paddingRight="10px">
                            <Input
                                value={poNumber}
                                label={"PO Number"}
                                disabled={true}
                            />
                        </FormColumn>
                        <FormColumn paddingLeft="10px">
                            <Dropdown
                                allowClear={true}
                                error={customErrors.partDescription}
                                value={inputs.partDescription}
                                label={'Part Description'}
                                options={partDescriptions}
                                required={true}
                                disabled={viewMode}
                                keyToShow={''}
                                onChange={partDesc => handleDropdownChange('partDescription', partDesc, () => setInputs({ ...inputs, partDescription: partDesc }), true)} />
                            {/* <Input
                                error={customErrors.partDescription}
                                value={inputs.partDescription}
                                label={"Part Description"}
                                onChange={(text: string) => onInputChange(text, 'partDescription', 'string')}
                            /> */}
                        </FormColumn>
                    </FormRow>

                    <FormRow>
                        <FormColumn paddingRight="10px">
                            <Input
                                error={customErrors.extendedCost}
                                value={inputs.extendedCost}
                                disabled={true}
                                label={"Total line amount"}
                                onChange={(text: string) => onInputChange(text, 'extendedCost', 'string', false)}
                            />
                        </FormColumn>

                        <FormColumn paddingLeft='10px'>
                            <DateSelector
                                error={customErrors.confirmedDate}
                                value={inputs.confirmedDate}
                                label={"Confirmed Date (Ship Date)"}
                                required={true}
                                disabled={viewMode}
                                onChange={(text: Date) => onInputChange(text, 'confirmedDate', 'date', true)}
                            />
                        </FormColumn>
                    </FormRow>

                    <FormRow>
                        <FormColumn paddingRight="10px">
                            {/* CustomerRequestedDate */}
                            <Input error={customErrors.customerRequestedDate}
                                label={'Customer Requested Date'}
                                value={inputs.customerRequestedDate}
                                disabled={true} />
                        </FormColumn>

                        <FormColumn paddingLeft='10px'>
                            <DateSelector
                                error={customErrors.revisedCustomerRequestedDate}
                                value={inputs.revisedCustomerRequestedDate}
                                required={true}
                                disabled={viewMode}
                                label={"Revised Customer Requested Date (Customer Desired Date)"}
                                onChange={(text: Date) => onInputChange(text, 'revisedCustomerRequestedDate', 'date', true)}
                            />
                        </FormColumn>
                    </FormRow>

                    <FormRow>
                        <FormColumn paddingRight="10px">
                            <Dropdown
                                allowClear={true}
                                error={customErrors.partId}
                                value={inputs.partId}
                                label={'Part ID'}
                                options={partIds}
                                required={true}
                                keyToShow={''}
                                disabled={viewMode}
                                onChange={partId => handleDropdownChange('partId', partId, () => setInputs({ ...inputs, partId }), true) } />
                            {/* <Input
                                error={customErrors.partId}
                                value={inputs.partId}
                                label={"Part ID"}
                                onChange={(text: string) => onInputChange(text, 'partId', 'string')}
                            /> */}
                        </FormColumn>
                        <FormColumn paddingLeft="10px">
                            <Input
                                error={customErrors.customerPartId}
                                value={inputs.customerPartId}
                                label={"Customer Part ID"}
                                disabled={viewMode}
                                required={false}
                                maxLength={250}
                                onInternalError={onInternalInputErrors}
                                inputId={'customerPartId'}
                                onChange={(text: string) => onInputChange(text, 'customerPartId', 'string', false)}
                            />
                        </FormColumn>
                    </FormRow>

                    <FormRow>
                        <FormColumn paddingRight="10px">
                            <Input
                                error={customErrors.quantity}
                                value={inputs.quantity}
                                label={"Quantity"}
                                disabled={true}
                                onChange={(text: string) => onInputChange(text, 'quantity', 'string')}
                            />
                        </FormColumn>
                        <FormColumn paddingLeft="10px">
                            <Input
                                type="number"
                                minValue="1"
                                error={customErrors.revisedQuantity}
                                value={inputs.revisedQuantity}
                                label={"Revised Quantity"}
                                required={true}
                                disabled={viewMode}
                                onChange={(text: string) => onInputChange(text, 'revisedQuantity', 'string', true)}
                            />
                        </FormColumn>
                    </FormRow>

                    <FormRow>
                        <FormColumn paddingRight="10px">
                            <Input
                                error={customErrors.unitPrice}
                                value={inputs.unitPrice}
                                disabled={true}
                                label={"Unit Price"}
                                onChange={(text: string) => onInputChange(text, 'unitPrice', 'string')}
                            />
                        </FormColumn>
                        <FormColumn paddingLeft="10px">
                            <Input
                                type="number"
                                minValue="0"
                                error={customErrors.revisedUnitPrice}
                                value={inputs.revisedUnitPrice}
                                required={true}
                                label={"Revised Unit Price"}
                                disabled={viewMode}
                                onChange={(text: string) => onInputChange(text, 'revisedUnitPrice', 'string', true)}
                            />
                        </FormColumn>
                    </FormRow>

                    <FormRow>
                        <FormColumn paddingRight='10px'>
                            <Dropdown value={siteId}
                                options={siteIds}
                                keyToShow={'siteId'}
                                required={true}
                                error={customErrors.siteId}
                                label={'Site ID'}
                                disabled={viewMode}
                                allowClear={true}
                                onChange={siteId => handleDropdownChange('siteId', siteId, () => setSiteId(siteId), true)} />
                        </FormColumn>

                        <FormColumn paddingLeft='10px'>
                            <Input type='text'
                                error={customErrors.text}
                                value={inputs.text}
                                label={'Text'}
                                disabled={viewMode}
                                onChange={(text: string) => onInputChange(text, 'text', 'string')} />
                        </FormColumn>
                    </FormRow>
                    <FormRow>
                        {
                            !isEmpty(customErrors.info) &&
                            <FormColumn width='700px'>
                                <Input
                                    type='hidden'
                                    error={customErrors.info}
                                    value={''}
                                    disabled={viewMode}
                                />
                            </FormColumn>
                        }
                    </FormRow>

                    <ButtonWrapper>
                        <Button disabled={isSaveButtonDisabled()} label={'Submit'} onClick={handleSubmit} />

                        {/* <Button label={'Validate'} onClick={() => onValidateClick()} /> */}
                    </ButtonWrapper>
                </FormWrapper>
            </Modal>
        );
    }
};
