import activCartsLogo from './shared/assets/activ-carts-logo.png'
import geckoLogo from "./shared/assets/gecko-logo.png";

export const Config = {
    gecko: {
        logo: geckoLogo,
        links: {
            documentations: "https://geckoalliance.com/support/documentation/",
            clips: "https://geckoalliance.com/resources/videos/",
            events: "https://geckoalliance.com/events/trade-shows/",
            seminars: "https://geckoalliance.com/events/technical-seminars/",
            inTouch2: "https://gdlearning.squarespace.com/connectedintouch2",
            cases: "https://gdlearning.squarespace.com/my-cases#my-customer-portal/"
        }
    },
    activCarts: {
        logo: activCartsLogo
    },
    API_KEY_PARSER: '18500618aeaf2a8472782124a90f45322a3a4307',
};
